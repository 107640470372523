/**
 * ResetPassword
 * 用于重置密码的页面
 */
import React, { Component } from 'react';
import { resetpassword } from '../../utils/UserFunctions';
import { Grid, Card, Form, Button, Icon, Label } from 'semantic-ui-react';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class ResetPassword extends Component{
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            password2: '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name] : e.target.value})
    }

    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.setState({
                password: '',
                password2: ''
            })
        });
    };

    successNotify = () => {
        toast.info("用户密码更新成功...", {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER
        });
    }

    componentDidMount() {
        const token = localStorage.usertoken;
        if (token) {
            const decoded = jwt_decode(token);
            this.setState({
                email: decoded.email
            });
        }
    }

    onSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            const user = {
                email: this.state.email,
                password: this.state.password,
                password2: this.state.password2
            }
            resetpassword(user).then(res => {
                if (res.success) {
                    this.successNotify();
                } else {
                    this.errNotify('用户初始密码错误');
                }
            })
        }
    }

    /**
     * 注册验证函数
     */
    validateForm = () => {
        if (this.state.password === this.state.password2) {
            this.errNotify('新密码与旧密码一致');
            return false;
        }
        if (this.state.password2.length < 8) {
            this.errNotify('新密码长度小于8位');
            return false;
        }
        return true;
    }

    render() {
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column floated='middle' mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Reset Password</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form onSubmit={this.onSubmit} style={{ textAlign: 'left'}}>
                                    <Label pointing='below' as='a' color='green'>Your Email</Label>
                                    <Form.Input fluid icon='user' iconPosition='left'  type="email" placeholder="Enter email" name="email"
                                        value={this.state.email} onChange={this.onChange} required disabled />
                                    <Label pointing='below' as='a' color='green'>Your Password</Label>
                                    <Form.Input fluid icon='lock' iconPosition='left'  type="password" placeholder="Password must large 8 bit." name="password"
                                            value={this.state.password} onChange={this.onChange} required/>
                                    <Label pointing='below' as='a' color='green'>New Password</Label>
                                    <Form.Input fluid icon='lock' iconPosition='left' type="password" placeholder="Password1 and Pawword2 must be same." name="password2"
                                            value={this.state.password2} onChange={this.onChange} required/>
                                    <Button floated='right' icon labelPosition='left' primary size='small' type="submit">
                                        <Icon name='refresh'></Icon>Reset
                                    </Button>
                                </Form>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default ResetPassword;
/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-03 00:33:22
 * @FilePath: \rem-rest-api\client\src\components\menu\crumb\HotelCrumb.js
 */
/**
 * Hotel BreadCrumb
 */
import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'

class HotelCrumb extends Component{
    render() {
        return (
            <Breadcrumb size="small">
                <Breadcrumb.Section href="/hotelpanel">Location</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/capsuletypepanel">Capsule Type</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/roompanel">Capsule</Breadcrumb.Section>
            </Breadcrumb>
        );
    }
}
export default HotelCrumb;
import axios from 'axios'

/**
 * 用户注册
 */
export const register = newUser => {
    return axios.post("api/auth/signup", {
        username: newUser.username,
        email: newUser.email,
        password: newUser.password
    }).then(res => {
        return res.data
    }).catch(err => {
        return err
    });
}

/**
 * 用户登录
 */
export const login = user => {
    return axios.post("api/auth/login", {
        email: user.email,
        password: user.password
    }).then(res => {
        // 登录成功后将token保存在localStorage的usertoken中
        localStorage.setItem("usertoken", res.data.id.split(" ")[1]);
        return res.data
    }).catch(err => {
        return err
    });
}


/**
 * 用户重置密码
 */
export const resetpassword = user => {
    return axios.post("api/auth/resetpassword", {
        email: user.email,
        password: user.password,
        password2: user.password2
    }).then(res => {
        return res.data
    }).catch(err => {
        return err
    });
}
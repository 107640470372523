/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-03 00:33:00
 * @FilePath: \rem-rest-api\client\src\components\menu\SidebarMenu.js
 */
/**
 * SidebarMenu
 * 业务界面左侧导航栏
 */
import React, { Component } from 'react';
import { Menu, Header, Icon } from 'semantic-ui-react';
import './SidebarMenu.css';

class SidebarMenu extends Component {
    render() {
        return (
            <Menu vertical className="ui.vertical.menu">
                <Menu.Item>
                    <Header as='h3'>Business</Header>
                </Menu.Item>
                <Menu.Item name='geomap'>
                    <Icon name='warehouse' />Service
                    <Menu.Menu>
                        <Menu.Item name='side_map' href="/hotelpanel">
                            Location
                        </Menu.Item>
                        <Menu.Item name='side_map2' href="/capsuletypepanel">
                            Capsule Type
                        </Menu.Item>
                        <Menu.Item name='side_map3' href="/roompanel">
                            Capsule
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Icon name='podcast' />Devices
                    <Menu.Menu>
                        <Menu.Item name='side_chart' href="/devicepanel">
                            Device
                        </Menu.Item>
                        <Menu.Item name='side_chart' href="/ippanel">
                            IP
                        </Menu.Item>
                        <Menu.Item name='side_chart' href="/remsoundpanel">
                            R.E.M. Sounds
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item>
                    <Icon name='bed' />Sleep Data
                    <Menu.Menu>
                        <Menu.Item name='side_chart' href="/emfitpanel">
                            Emfit Data
                        </Menu.Item>
                        <Menu.Item name='side_chart' href="/emfitshowpanel">
                            Emfit Visualization
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
                <Menu.Item name='browse'>
                    <Icon name='user' />User Setting
                    <Menu.Menu>
                        <Menu.Item name='side_userinfo' href="/userpanel">
                            UserInfo
                        </Menu.Item>
                        <Menu.Item name='side_resetpassword' href="/resetpassword">
                            Reset Password
                        </Menu.Item>
                    </Menu.Menu>
                </Menu.Item>
            </Menu>
        )
    }
}
export default SidebarMenu;
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button, Checkbox, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './REMSoundAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class REMSoundAdd extends Component {
    constructor(props) {
        super(props);

        this.state = {
            name: "",
            artist: "",
            duration: "",
            url: "",
            isIncluded: false
        }
    }

    /**
     * 成功通知
     */
    successNotify = () => toast.success("Sound Added Successfully...", {
        onClose: () => this.props.history.push('/remsoundpanel')
    });

    /**
     * 错误通知
     */
    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER
        });
    };

    /**
     * Form.Input内容变化的处理函数
     */
    onChange = (e, { name, value }) => this.setState({ [name]: value })


    handleUpload = (e) => {
        let file = this.uploadInput.files[0];
        // Split the filename to get the name and type
        let fileParts = this.uploadInput.files[0].name.split('.');
        let fileName = fileParts[0];
        let fileType = fileParts[1];
        console.log("Preparing the upload");
        axios.post("/api/remsound/file-upload", {
            fileName: fileName,
            fileType: fileType
        })
            .then(response => {
                var returnData = response.data.data.returnData;
                var signedRequest = returnData.signedRequest;
                var url = returnData.url;
                this.setState({ url: url })
                console.log("Recieved a signed request " + signedRequest);

                // Put the fileType in the headers for the upload
                var options = {
                    headers: {
                        'Content-Type': fileType
                    }
                };
                axios.put(signedRequest, file, options)
                    .then(result => {
                        console.log("Response from s3")
                        this.setState({ success: true });
                    })
                    .catch(error => {
                        alert("ERROR " + JSON.stringify(error));
                    })
            })
            .catch(error => {
                alert(JSON.stringify(error));
            })
    }


    handleFileChange = (e) => {

        var file = this.uploadInput.files[0];
        this.setState({ url: `https://rem-sound.s3.amazonaws.com/${file.name}` })

        // TODO: Automate duration finding
        // var audio = document.createElement('audio');
        // var reader = new FileReader();
        // reader.onload = (e) => {
        //     audio.src = e.target.result;
        //     audio.addEventListener('loadedmetadata', function () {
        //         var seconds = audio.duration;
        //         var duration = moment.duration(seconds, "seconds");
        //         var time = "";
        //         var hours = duration.hours();
        //         if (hours > 0) { time = hours + ":"; }
        //         time = time + duration.minutes() + ":" + duration.seconds();
        //         console.log("The Duration of the song: " + time)
        //         // Getting seState not a function error here
        //         // this.setState({ duration: time })
        //     }, false).bind(this);
        // }
        // reader.readAsDataURL(file);

    }


    //Checkbox set state
    toggle = () => this.setState((prevState) => ({ isIncluded: !prevState.isIncluded }))

    /**
     * Add方法
     */
    add() {
        if (this.state.name.length === 0 || this.uploadInput.files.length === 0 || this.state.duration.length === 0) {
            this.errNotify('Required Fields are Empty');
        } else {

            let file = this.uploadInput.files[0];
            // Split the filename to get the name and type
            let fileParts = this.uploadInput.files[0].name.split('.');
            let fileName = file.name;
            let fileType = fileParts[1];

            axios.post("/api/remsound/fileupload", {
                fileName: fileName,
                fileType: fileType
            })
                .then(response => {
                    var returnData = response.data.data.returnData;
                    var signedRequest = returnData.signedRequest;
                    var url = returnData.url;
                    this.setState({ url: url })
                    console.log("Recieved a signed request " + signedRequest);

                    // Put the fileType in the headers for the upload
                    var options = {
                        headers: {
                            'Content-Type': fileType
                        }
                    };
                    axios.put(signedRequest, file, options)
                        .then(result => {
                            console.log("File Uploaded")
                            toast.success("File Uploaded");

                            axios.post("/api/remsound/sounds", {
                                name: this.state.name.trimRight(),
                                artist: this.state.artist,
                                duration: this.state.duration,
                                url: this.state.url,
                                isIncluded: this.state.isIncluded

                            }, {

                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.usertoken
                                }
                            }).then(res => {
                                this.successNotify();
                            }).catch(err => {
                                console.log(err);
                            });
                        })
                        .catch(error => {
                            alert("ERROR " + JSON.stringify(error));
                        })
                })
                .catch(error => {
                    alert(JSON.stringify(error));
                })
        }
    }

    render() {
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="music"></Icon>&nbsp;&nbsp;Add Sound</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left' }}>
                                    <Form.Input label='Sound Name' fluid icon='music' iconPosition='left' type="text" name="name"
                                        onChange={this.onChange} required />
                                    <Form.Input label='Artist' fluid icon='user' iconPosition='left' type="text" name="artist"
                                        onChange={this.onChange} />

                                    <Form.Input label='Duration' fluid icon='clock' iconPosition='left' type="text" name="duration"
                                        onChange={this.onChange} required />
                                    {/* File Upload input */}
                                    <Form.Field label='Audio File' />
                                    <input onChange={this.handleFileChange} ref={(ref) => { this.uploadInput = ref; }} type="file" className="bottom-space" required />


                                    <Label style={{ marginTop: '10px' }}>
                                        <Form.Field>
                                            <Checkbox label="isIncluded" name="isIncluded" checked={this.state.isIncluded} onChange={this.toggle} />
                                        </Form.Field>
                                    </Label>

                                    <Form.Input label='URL' fluid icon='linkify' iconPosition='left' type="text" name="url" value={this.state.url}
                                        onChange={this.onChange} disabled />

                                </Form>

                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid >
        );
    }
}
export default withRouter(REMSoundAdd);
/**
 * RoomEdit
 * Room具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './RoomEdit.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class RoomEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            label: '',
            roomid: '',
            hotel: null,
            hotels: null,
            type: null,
            types: null
        }
    }

    componentWillMount(){
        axios.get("/api/rooms/" + this.props.id ,{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const room = res.data.room;
                this.setState({
                    loaded: true,
                    roomid: room.roomid,
                    label: room.label,
                    hotel: room.hotel._id,
                    type: room.capsuletype ? room.capsuletype._id : null
                });
            }
        }).catch(err => {
            console.log(err);
        });

        axios.get("/api/hotels/",{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const hotels = [];
                res.data.hotels.map((hotel, index) => { 
                    return(hotels.push({key: index, text: hotel.name, value: hotel.id}))
                })
                this.setState({
                    hotels: hotels
                })
            }
        }).catch(err => {
            console.log(err);
        });

        axios.get("/api/capsuletypes/",{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const types = [];
                res.data.capsuletypes.map((hotel, index) => { 
                    return(types.push({key: index, text: hotel.name, value: hotel.id}))
                })
                this.setState({
                    types: types
                })
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("Capsule Update Successfully...", {
        onClose: () => this.props.history.push('/roompanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]: value })


    update() {
        axios.patch("/api/rooms/" + this.props.id, {
            label: this.state.label,
            roomid: this.state.roomid,
            hotel: this.state.hotel,
            type: this.state.type
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
            }).then(res => {
                this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        const { roomid, label, hotel, hotels, type, types } = this.state;
        const values = { roomid, label, hotel, hotels, type, types };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Capsule Update</h3>
                            </Card.Content>
                            <Card.Content>
                            {this.state.loaded ? (
                                <Form style={{ textAlign: 'left'}}>
                                    <Form.Input label='Capsule Label' fluid icon='hotel' iconPosition='left'  type="text" name="label"
                                        onChange={this.onChange} required  defaultValue={values.label}/>
                                    <Form.Input label='Product ID' fluid icon='hotel' iconPosition='left'  type="text" name="roomid"
                                        defaultValue={values.roomid} onChange={this.onChange} required />
                                    <Form.Dropdown label='Location' selection placeholder='Choose Location' name="hotel" 
                                        options={values.hotels} onChange={this.onChange} value={values.hotel}/>
                                    <Form.Dropdown label='Capsule Type' selection placeholder='Choose Capsule Type' name="type" 
                                        options={values.types} onChange={this.onChange} value={values.type}/>
                                </Form>
                            ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(RoomEdit);
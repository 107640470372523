/**
 * EmfitSummary BreadCrumb
 */
import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'

class EmfitCrumb extends Component{
    render() {
        return (
            <Breadcrumb size="small">
                <Breadcrumb.Section href="/emfitpanel">Emfit Data</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/emfitshowpanel">Emfit Vis</Breadcrumb.Section>
            </Breadcrumb>
        );
    }
}
export default EmfitCrumb;
/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:54:45
 * @FilePath: \rem-rest-api\client\src\components\menu\crumb\DeviceCrumb.js
 */
/**
 * Device BreadCrumb
 */
import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'

class DeviceCrumb extends Component {
    render() {
        return (
            <Breadcrumb size="small">
                <Breadcrumb.Section href="/devicepanel">Device</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/ippanel">IP</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/remsound">R.E.M. Sound</Breadcrumb.Section>
            </Breadcrumb>
        );
    }
}
export default DeviceCrumb;
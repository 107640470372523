/**
 * Auth BreadCrumb
 */
import React, { Component } from 'react'
import { Breadcrumb } from 'semantic-ui-react'

class AuthCrumb extends Component{
    render() {
        return (
            <Breadcrumb size="small">
                <Breadcrumb.Section href="/userpanel">User Info</Breadcrumb.Section>
                <Breadcrumb.Divider>/</Breadcrumb.Divider>
                <Breadcrumb.Section href="/resetpassword">Reset Password</Breadcrumb.Section>
            </Breadcrumb>
        );
    }
}
export default AuthCrumb;
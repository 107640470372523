/**
 * Register
 * 用于注册的页面
 */
import React, { Component } from 'react';
import { register } from '../utils/UserFunctions';
import { Grid, Container, Card, Form, Button, Icon, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import validator from 'validator';

class RegisterPanel extends Component{
    constructor() {
        super();
        this.state = {
            username: '',
            email: '',
            password: '',
            password2: '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onChange(e) {
        this.setState({[e.target.name] : e.target.value})
    }

    /**
     * 错误通知
     */
    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.setState({
                username: '',
                email: '',
                password: '',
                password2: ''
            })
        });
    };

    /**
     * 注册成功通知
     */
    successNotify = () => {
        toast.info("用户注册成功,2秒后跳转至登录页面...", {
            autoClose: 1500,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.props.history.push('/login')
        });
    }

    /**
     * 提交注册按钮
     * @param {} e 
     */
    onSubmit(e) {
        e.preventDefault();
        if (this.validateForm()) {
            const user = {
                username: this.state.username,
                email: this.state.email,
                password: this.state.password
            }
            register(user).then(res => {
                console.log(res);
                if (!res.success) {
                    this.errNotify('该用户已经注册');
                } else {
                    this.successNotify();
                }
            })
        }
    }

    /**
     * 注册验证函数
     */
    validateForm = () => {
        if (this.state.password !== this.state.password2) {
            this.errNotify('两次输入的密码不一致');
            return false;
        }
        if (!validator.isEmail(this.state.email)) {
            this.errNotify('Email格式不正确');
            return false;
        }
        if (this.state.password.length < 8) {
            this.errNotify('密码长度小于8位');
            return false;
        }
        return true;
    }

    render() {
        return (
            <Grid textAlign='center' style={{ height: '100%', marginTop:'100px', marginLeft:'unset', marginRight:'unset' }} verticalAlign='middle'>
                <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Card fluid color='blue' style={{width:'unset'}}>
                        <Card.Content>
                        <h3><Icon name="registered"></Icon>&nbsp;&nbsp;用户注册</h3>
                        </Card.Content>
                        <Card.Content>
                            <Form onSubmit={ this.onSubmit } style={{ textAlign: 'left' }}>
                                <Label pointing='below' as='a' color='red'>Your Username</Label>
                                <Form.Input fluid icon='user' iconPosition='left'  type="text" placeholder="Enter Username" name="username"
                                    value={ this.state.username } onChange={ this.onChange } required autoFocus />
                                <Label pointing='below' as='a' color='red'>Your Email</Label>
                                <Form.Input fluid icon='mail' iconPosition='left'  type="email" placeholder="Enter email" name="email"
                                    value={ this.state.email } onChange={ this.onChange } required />
                                <Label pointing='below' as='a' color='red'>Your Password</Label>
                                <Form.Input fluid icon='lock' iconPosition='left'  type="password" placeholder="Password must large 8 bit." name="password"
                                        value={ this.state.password } onChange={ this.onChange } required/>
                                <Label pointing='below' as='a' color='red'>Password Confirm</Label>
                                <Form.Input fluid icon='lock' iconPosition='left' type="password" placeholder="Password1 and Pawword2 must be same." name="password2"
                                        value={ this.state.password2 } onChange={ this.onChange } required/>
                                <Container textAlign="center">
                                    <Button type="submit" color='red'>
                                        SignUp
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button primary type="button" onClick={() => this.props.history.push('/login')}>
                                        SignIn
                                    </Button>
                                </Container>
                            </Form>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        );
    }
}
export default RegisterPanel;
/**
 * 主要用于显示User Model数据
 * 实现了数据的展示与删除功能
 * 数据展示：
 * 数据删除：
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Icon, Pagination, Button } from 'semantic-ui-react';
import AuthCrumb from '../../../menu/crumb/AuthCrumb';
import axios from 'axios';
import moment from 'moment';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import './UserTable.css';

class UserTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            userdata: null,
            column: null,
            direction: null,
            pageItemCount: 15,
            activePage: 1,
            boundaryRange: 0,
            siblingRange: 1,
            showEllipsis: true,
            showFirstAndLastNav: true,
            showPreviousAndNextNav: true,
            totalPages: 1,
        }
    }

    componentWillMount() {
        // 默认显示第1页
        this.getUserList(0);
    }

    getUserList = (offset) => {
        // 获取用户信息时将token添加在headers中发送
        axios.get("/api/auth/user?offset=" + offset + "&limit=" + this.state.pageItemCount , {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            this.setState({
                loading: false,
                userdata: res.data.results,
                totalPages: Number.isInteger(res.data.count / this.state.pageItemCount) ?  Math.floor(res.data.count / this.state.pageItemCount) : Math.floor(res.data.count / this.state.pageItemCount) + 1,  
            });
        }).catch(err => {
            console.log(err);
        });
    }

    // pagination时处理翻页
    setActivePage = (activePage) => {
        this.setState({ activePage: activePage });
        this.getUserList(( activePage - 1) * this.state.pageItemCount );
    }

    handlePaginationChange = (e, { activePage }) => {
        this.setActivePage(activePage);
    }
    
    // 删除按钮触发事件
    deleteClick(id) {
        axios.delete("/api/auth/user/"+ id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                this.getUserList(( this.state.activePage - 1) * this.state.pageItemCount);
            }
        }).catch(err => {
            console.log(err);
        });
    }

    // 编辑按钮触发事件
    editClick(id) {
        this.props.history.push('/useredit/' + id);
    }

    handleSort = clickedColumn => () => {
        const { column, userdata, direction } = this.state
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                userdata: _.sortBy(userdata, [clickedColumn]),
                direction: 'ascending',
          })
          return
        }
        this.setState({
            userdata: userdata.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }
    
    render() {
        const { column, direction, activePage, boundaryRange, siblingRange, showEllipsis, showFirstAndLastNav, showPreviousAndNextNav, totalPages } = this.state
        // 判断是否为Admin，以此确定是否有删除和修改属性的权力
        const isAdmin = jwt_decode(localStorage.usertoken).role === 'Admin' ? true : false;
        return (
            <Grid >
                <Grid.Row>
                    <Grid.Column>
                        <AuthCrumb></AuthCrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <Table color="red" celled selectable striped sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'email' ? direction : null} onClick={this.handleSort('email')}>Email</Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'username' ? direction : null} onClick={this.handleSort('username')}>UserName</Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'role' ? direction : null} onClick={this.handleSort('role')}>Role</Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'active' ? direction : null} onClick={this.handleSort('active')}>IsActive</Table.HeaderCell>
                                    <Table.HeaderCell>CreatedAt</Table.HeaderCell>
                                    {isAdmin ? (<Table.HeaderCell>Actions</Table.HeaderCell>) : ('')}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.state.loading ? (
                                <Table.Row>
                                    <Table.Cell colSpan='7' textAlign='center'>
                                        <Icon name='sync alternate' size='huge' color="red" loading></Icon>
                                    </Table.Cell>
                                </Table.Row>
                                ) : (
                                    this.state.userdata.map((user, index) => {
                                        return (
                                            <Table.Row key={index}>
                                                <Table.Cell collapsing>{index+1}</Table.Cell>
                                                <Table.Cell>{user.email}</Table.Cell>
                                                <Table.Cell>{user.username}</Table.Cell>
                                                <Table.Cell>{user.role}</Table.Cell>
                                                <Table.Cell>{ user.active ? (<Icon name='checkmark'/>) : (<Icon name='close' color='red'/>)}</Table.Cell>
                                                <Table.Cell>{ moment(user.createdAt).format("YYYY-MM-DD")}</Table.Cell>
                                                {isAdmin ? (<Table.Cell collapsing >
                                                    <Button primary circular icon="edit" onClick={(e, props, id) => this.editClick(user.id)}  ></Button>
                                                    <Button negative circular icon="delete" onClick={(e, props, id) => this.deleteClick(user.id)}></Button>
                                                </Table.Cell>) : ('')}
                                            </Table.Row>
                                        )
                                    })
                            )}</Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='7'>
                                    <Pagination
                                        activePage={activePage}
                                        boundaryRange={boundaryRange}
                                        onPageChange={this.handlePaginationChange}
                                        size='mini'
                                        siblingRange={siblingRange}
                                        totalPages={totalPages}
                                        ellipsisItem={showEllipsis ? undefined : null}
                                        firstItem={showFirstAndLastNav ? undefined : null}
                                        lastItem={showFirstAndLastNav ? undefined : null}
                                        prevItem={showPreviousAndNextNav ? undefined : null}
                                        nextItem={showPreviousAndNextNav ? undefined : null}
                                    />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(UserTable);
/*
 * @Author: Ajay Rajnikanth
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:31:44
 * @FilePath: \rem-rest-api\client\src\components\dataviz\hoteltable\page\HotelEdit.js
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button, Checkbox, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './REMSoundEdit.css';


toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class REMSoundEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            name: "",
            artist: "",
            duration: "",
            url: "",
            isIncluded: true
        }
    }

    componentDidMount() {
        axios.get("/api/remsound/sounds/" + this.props.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const sound = res.data.result;
                this.setState({
                    loaded: true,
                    name: sound.name,
                    artist: sound.artist,
                    duration: sound.duration,
                    url: sound.url,
                    isIncluded: sound.isIncluded
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("Sound Updated Successfully...", {
        onClose: () => this.props.history.push('/remsoundpanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]: value })

    //Checkbox set state
    toggle = () => this.setState((prevState) => ({ isIncluded: !prevState.isIncluded }))

    handleFileChange = (e) => {

        var file = this.uploadInput.files[0];
        this.setState({ url: `https://rem-sound.s3.amazonaws.com/${file.name}` })

        // TODO: Automate duration finding
        // var audio = document.createElement('audio');
        // var reader = new FileReader();
        // reader.onload = (e) => {
        //     audio.src = e.target.result;
        //     audio.addEventListener('loadedmetadata', function () {
        //         var seconds = audio.duration;
        //         var duration = moment.duration(seconds, "seconds");
        //         var time = "";
        //         var hours = duration.hours();
        //         if (hours > 0) { time = hours + ":"; }
        //         time = time + duration.minutes() + ":" + duration.seconds();
        //         console.log("The Duration of the song: " + time)
        //         // Getting seState not a function error here
        //         // this.setState({ duration: time })
        //     }, false).bind(this);
        // }
        // reader.readAsDataURL(file);

    }

    update() {
        let file = this.uploadInput.files[0];


        if (file) {
            // Split the filename to get the name and type
            let fileParts = this.uploadInput.files[0].name.split('.');
            let fileName = file.name;
            let fileType = fileParts[1];

            axios.post("/api/remsound/fileupload", {
                fileName: fileName,
                fileType: fileType
            })
                .then(response => {
                    var returnData = response.data.data.returnData;
                    var signedRequest = returnData.signedRequest;
                    var url = returnData.url;
                    this.setState({ url: url })
                    console.log("Recieved a signed request " + signedRequest);

                    // Put the fileType in the headers for the upload
                    var options = {
                        headers: {
                            'Content-Type': fileType
                        }
                    };
                    axios.put(signedRequest, file, options)
                        .then(result => {
                            console.log("File Uploaded")
                            toast.success("File Uploaded");

                            axios.patch("/api/remsound/sounds/" + this.props.id, {
                                name: this.state.name.trimRight(),
                                artist: this.state.artist,
                                duration: this.state.duration,
                                url: this.state.url,
                                isIncluded: this.state.isIncluded

                            }, {

                                headers: {
                                    'Authorization': 'Bearer ' + localStorage.usertoken
                                }
                            }).then(res => {
                                this.successNotify();
                            }).catch(err => {
                                console.log(err);
                            });
                        })
                        .catch(error => {
                            alert("ERROR " + JSON.stringify(error));
                        })
                })
                .catch(error => {
                    alert(JSON.stringify(error));
                })
        }
        else {
            axios.patch("/api/remsound/sounds/" + this.props.id, {
                name: this.state.name.trimRight(),
                artist: this.state.artist,
                duration: this.state.duration,
                url: this.state.url,
                isIncluded: this.state.isIncluded

            }, {

                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken
                }
            }).then(res => {
                this.successNotify();
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        const values = this.state;
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={12} computer={12}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Sound Update</h3>
                            </Card.Content>
                            <Card.Content>
                                {this.state.loaded ? (

                                    <Form style={{ textAlign: 'left' }}>
                                        <Form.Input label='Sound Name' fluid icon='music' iconPosition='left' type="text" name="name"
                                            defaultValue={values.name} onChange={this.onChange} required />
                                        <Form.Input label='Artist' fluid icon='user' iconPosition='left' type="text" name="artist"
                                            defaultValue={values.artist} onChange={this.onChange} required />
                                        <Form.Input label='Duration' fluid icon='clock' iconPosition='left' type="text" name="duration"
                                            defaultValue={values.duration} onChange={this.onChange} required />
                                        {/* File Upload input */}
                                        <Form.Field label='Audio File' />
                                        <input onChange={this.handleFileChange} ref={(ref) => { this.uploadInput = ref; }} type="file" className="bottom-space" required />


                                        <Label style={{ marginTop: '10px' }}>
                                            <Form.Field>
                                                <Checkbox label="isIncluded" name="isIncluded" checked={this.state.isIncluded} onChange={this.toggle} />
                                            </Form.Field>
                                        </Label>

                                        <Form.Input label='URL' fluid icon='linkify' iconPosition='left' type="text" name="url" value={this.state.url}
                                            onChange={this.onChange} disabled />
                                    </Form>

                                ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(REMSoundEdit);
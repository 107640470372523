/**
 * EmfitEdit
 * Emfit具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Table } from 'semantic-ui-react';
import moment from 'moment';
import Chart from 'react-apexcharts';
import axios from 'axios';
import './EmfitEdit.css';

class EmfitEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            emfit: null,
        }
    }

    componentWillMount(){
        axios.get("/api/data/summary/" + this.props.id ,{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const emfit = res.data.emfit;
                this.setState({
                    emfit: emfit
                }); 
            }
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        const { emfit } = this.state;
        const values = { emfit };
        var avg_options, avg_series = null;
        var duration_options, duration_series;
        var hrv_options, hrv_series;
        if (this.state.emfit) {
            avg_options = {
                xaxis: {
                    categories: ['AVG_HR', 'AVG_RR', 'AVG_ACT']
                },
                dataLabels: {
                    enabled: true,
                }
            };
            avg_series = [{
                name: 'AVG',
                data: [this.state.emfit.avg_hr, this.state.emfit.avg_rr, this.state.emfit.avg_act]
            }];
            duration_options = {
                xaxis: {
                    categories: ['duration', 'duration_in_bed', 'duration_awake', 'duration_in_sleep', 'duration_in_rem', 'duration_in_light','duration_in_deep','duration_sleep_onset']
                },
                dataLabels: {
                    enabled: true,
                }
            };
            duration_series = [{
                name: 'Duration',
                data: [this.state.emfit.duration, this.state.emfit.duration_in_bed, this.state.emfit.duration_awake,this.state.emfit.duration_in_sleep, this.state.emfit.duration_in_rem, this.state.emfit.duration_in_light, this.state.emfit.duration_in_deep, this.state.emfit.duration_sleep_onset]
            }];
            hrv_options = {
                xaxis: {
                    categories: ['hrv_score', 'hrv_lf', 'hrv_hf', 'hrv_rmssd_evening', 'hrv_rmssd_morning']
                },
                dataLabels: {
                    enabled: true,
                }
            };
            hrv_series = [{
                name: 'HRV',
                data: [this.state.emfit.hrv_score, this.state.emfit.hrv_lf, this.state.emfit.hrv_hf, this.state.emfit.hrv_rmssd_evening, this.state.emfit.hrv_rmssd_morning]
            }];
        }
        
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;EmfitSummary数据</h3>
                            </Card.Content>
                            <Card.Content>
                                {values.emfit ? (
                                    <Form style={{ textAlign: 'left' }}>
                                        <Table color="red" celled selectable striped sortable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Properties</Table.HeaderCell>
                                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>ID</Table.Cell>
                                                    <Table.Cell>{values.emfit.id}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>CreatedAt</Table.Cell>
                                                    <Table.Cell>{ moment(values.emfit.createdAt).format("YYYY-MM-DD hh:mm:ss")}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>Device</Table.Cell>
                                                    <Table.Cell>{values.emfit.device}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>From</Table.Cell>
                                                    <Table.Cell>{values.emfit.from}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>To</Table.Cell>
                                                    <Table.Cell>{values.emfit.to}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Chart options={avg_options} series={avg_series} type="area" width={'100%'} height={320} />
                                        <Chart options={duration_options} series={duration_series} type="line" width={'100%'} height={320} />
                                        <Table color="red" celled selectable striped sortable>
                                            <Table.Header>
                                                <Table.Row>
                                                    <Table.HeaderCell>Properties</Table.HeaderCell>
                                                    <Table.HeaderCell>Value</Table.HeaderCell>
                                                </Table.Row>
                                            </Table.Header>
                                            <Table.Body>
                                                <Table.Row>
                                                    <Table.Cell>sleep_score</Table.Cell>
                                                    <Table.Cell>{values.emfit.sleep_score}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>bedexit_duration</Table.Cell>
                                                    <Table.Cell>{values.emfit.bedexit_duration}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>bedexit_count</Table.Cell>
                                                    <Table.Cell>{values.emfit.bedexit_count}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>tossnturn_count</Table.Cell>
                                                    <Table.Cell>{values.emfit.tossnturn_count}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>from_gmt_offset</Table.Cell>
                                                    <Table.Cell>{values.emfit.from_gmt_offset}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>min_hr</Table.Cell>
                                                    <Table.Cell>{values.emfit.min_hr}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>max_hr</Table.Cell>
                                                    <Table.Cell>{values.emfit.max_hr}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>min_rr</Table.Cell>
                                                    <Table.Cell>{values.emfit.min_rr}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>max_rr</Table.Cell>
                                                    <Table.Cell>{values.emfit.max_rr}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>fm_count</Table.Cell>
                                                    <Table.Cell>{values.emfit.fm_count}</Table.Cell>
                                                </Table.Row>
                                                <Table.Row>
                                                    <Table.Cell>awakenings</Table.Cell>
                                                    <Table.Cell>{values.emfit.awakenings}</Table.Cell>
                                                </Table.Row>
                                            </Table.Body>
                                        </Table>
                                        <Chart options={hrv_options} series={hrv_series} type="area" width={'100%'} height={320} />
                                    </Form>
                                ):('') }
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(EmfitEdit);
/**
 * 系统底部的Footer
 */
import React, { Component } from 'react';
import { Container, Divider, Grid, Header, List, Segment, Icon } from 'semantic-ui-react'
import moment from 'moment';
import './Footer.css';

class Footer extends Component{
    render(){
        return (
            <Segment inverted vertical style={{ margin: "5em 0em 0em", padding: "2em 0em" }}>
                <Container textAlign="center">
                    <Grid divided inverted stackable>
                    <Grid.Column width={3}>
                        <Header inverted as="h4" content="Resouces" />
                        <List link inverted>
                        <List.Item as="a" href='https://sleeep.io/'>Sleeep.io</List.Item>
                        <List.Item as="a" href="https://qs.emfit.com/#/login">Emfit QS</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={3}>
                        <Header inverted as="h4" content="Social Media" />
                        <List link inverted>
                        <List.Item as="a" href='https://www.facebook.com/SLEEEP.global/'><Icon name='facebook f'></Icon>Facebook</List.Item>
                        <List.Item as="a" href='https://www.instagram.com/sleeep.global/'><Icon name='instagram'></Icon>Instagram</List.Item>
                        </List>
                    </Grid.Column>
                    <Grid.Column width={10}>
                        <Header inverted as="h4" content="About" />
                        <p>
                        “Sleep is an essential activity that is practiced by everyone, everyday, everywhere. 
                                Yet, in a stressful and overworked environment such as Hong Kong, 
                                that foundation is sacrificed in the name of improving living standards. 
                                We thought that contradictory and unsustainable.” says Jun Rivers, 
                                one of the two founders of SLEEEP. 
                                “With our new hospitality concept we hope to offer a sanctuary for people to relax and recharge. 
                                It’s a breathing space within a suffocating environment. We truly believe that high quality, 
                                sufficient sleep can take us further in both professional and personal lives by revitalizing our bodies, sharpening our minds, 
                                enhancing our creativity and strengthening our resilience.”.
                        </p>
                    </Grid.Column>
                    </Grid>
                    <Divider inverted section />
                    <List horizontal inverted divided link size="small">
                    <List.Item as="a" href="/">
                        Powerd By SLEEEP - {moment(moment.now()).format("YYYY")}
                    </List.Item>
                    </List>
                </Container>
            </Segment>            
        );
    }
}
export default Footer;
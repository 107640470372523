import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import REMSoundEdit from './page/REMSoundEdit';
import SidebarMenu from '../../menu/SidebarMenu';

class REMSoundEditPanel extends Component {
    render() {
        const { match: { params } } = this.props;
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <REMSoundEdit id={params.id}></REMSoundEdit>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}
export default REMSoundEditPanel;
/**
 * DeviceEdit
 * Device具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './IPEdit.css';


toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class IPEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            ip: null,
            serial_number: null,
        }
    }

    componentWillMount(){
        axios.get("/api/device/ips/" + this.props.id ,{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const ip = res.data.ip;
                this.setState({
                    loaded: true,
                    ip: ip.ip,
                    serial_number: ip.serial_number
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("IP Update Successfully...", {
        onClose: () => this.props.history.push('/ippanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]:  value })

    update() {
        axios.patch("/api/device/ips/" + this.props.id, {
            ip: this.state.ip,
            serial_number: this.state.serial_number
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
        }).then(res => {
            this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        const { ip, serial_number } = this.state;
        const values = { ip, serial_number};
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Update IP</h3>
                            </Card.Content>
                            <Card.Content>
                                {this.state.loaded ? (
                                    <Form style={{ textAlign: 'left' }}>
                                        <Form.Input label='Serial Number' fluid icon='plug' iconPosition='left' type="text" name="serial_number"
                                            defaultValue={values.serial_number} onChange={this.onChange} required />
                                        <Form.Input label='IP' fluid icon='plug' iconPosition='left' type="text" name="ip"
                                            defaultValue={values.ip} onChange={this.onChange} required />
                                    </Form>
                                ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}   
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(IPEdit);
/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 08:45:21
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-03 00:32:25
 * @FilePath: \rem-rest-api\client\src\App.js
 */
import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
// 负责授权验证的router组件
import EnsureLoggedInContainer from './components/utils/EnsureLoggedInContainer';
// 顶端和底部组件
import TopMenu from './components/menu/TopMenu';
import Footer from './components/footer/Footer';
// 默认界面组件
import MainPanel from './components/MainPanel';
// Auth部分组件
import LoginPanel from './components/auth/LoginPanel';
import RegisterPanel from './components/auth/RegisterPanel';
import ResetPwdPanel from './components/auth/ResetPwdPanel';
// Device部分组件
import DevicePanel from './components/dataviz/devicetable/DevicePanel';
import DeviceAddPanel from './components/dataviz/devicetable/DeviceAddPanel';
import DeviceEditPanel from './components/dataviz/devicetable/DeviceEditPanel';
import DeviceInfoPanel from './components/dataviz/devicetable/DeviceInfoPanel';
// IP部分组件
import IPPanel from './components/dataviz/iptable/IPPanel';
import IPAddPanel from './components/dataviz/iptable/IPAddPanel';
import IPEditPanel from './components/dataviz/iptable/IPEditPanel';
// Emfit数据组件
import EmfitPanel from './components/dataviz/emfittable/EmfitPanel';
import EmfitEditPanel from './components/dataviz/emfittable/EmfitEditPanel';
// Hotel部分组件
import HotelPanel from './components/dataviz/hoteltable/HotelPanel';
import HotelAddPanel from './components/dataviz/hoteltable/HotelAddPanel';
import HotelEditPanel from './components/dataviz/hoteltable/HotelEditPanel';
import CapsuleTypePanel from './components/dataviz/capsuletype/CapsuleTypePanel';
import CapsuleTypeAddPanel from './components/dataviz/capsuletype/CapsuleTypeAddPanel';
import CapsuleTypeEditPanel from './components/dataviz/capsuletype/CapsuleTypeEditPanel';
import RoomPanel from './components/dataviz/hoteltable/RoomPanel';
import RoomAddPanel from './components/dataviz/hoteltable/RoomAddPanel';
import RoomEditPanel from './components/dataviz/hoteltable/RoomEditPanel';
// 业务部分组件
import UserPanel from './components/dataviz/usertable/UserPanel';
import UserEditPanel from './components/dataviz/usertable/UserEditPanel';
// 图表组件
import EmfitShowPanel from './components/dataviz/emfittable/EmfitShowPanel';

//REM Sounds
import REMSoundPanel from './components/dataviz/remsound/REMSoundPanel';
import REMSoundAddPanel from './components/dataviz/remsound/REMSoundAddPanel';
import REMSoundEditPanel from './components/dataviz/remsound/REMSoundEditPanel';

class App extends Component {
    render() {
        return (
            <Router>
                <div className="App">
                    <TopMenu></TopMenu>
                    <Route exact path="/" component={MainPanel}></Route>
                    <Route exact path="/register" component={RegisterPanel}></Route>
                    <Route exact path="/login" component={LoginPanel}></Route>
                    <Switch>
                        <EnsureLoggedInContainer path="/resetpassword" component={ResetPwdPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/devicepanel" component={DevicePanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/deviceaddpanel" component={DeviceAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/deviceedit/:id" component={DeviceEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path='/device/info/:sn' component={DeviceInfoPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/ippanel" component={IPPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/ipaddpanel" component={IPAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/ipedit/:id" component={IPEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/hotelpanel" component={HotelPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/hoteladdpanel" component={HotelAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/hoteledit/:id" component={HotelEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/capsuletypepanel" component={CapsuleTypePanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/capsuletypeaddpanel" component={CapsuleTypeAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/capsuletypeedit/:id" component={CapsuleTypeEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/roompanel" component={RoomPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/roomaddpanel" component={RoomAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/roomedit/:id" component={RoomEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/emfitpanel" component={EmfitPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/emfitedit/:id" component={EmfitEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/userpanel" component={UserPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/useredit/:id" component={UserEditPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/emfitshowpanel" component={EmfitShowPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/remsoundpanel" component={REMSoundPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/remsoundaddpanel" component={REMSoundAddPanel}></EnsureLoggedInContainer>
                        <EnsureLoggedInContainer path="/remsoundedit/:id" component={REMSoundEditPanel}></EnsureLoggedInContainer>
                    </Switch>
                    <Footer></Footer>
                </div>
            </Router>
        );
    }
}
export default App;

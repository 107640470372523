/**
 * DeviceEdit
 * Device具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button, Segment } from 'semantic-ui-react';
import axios from 'axios';

import './DeviceEdit.css';

class DeviceInfo extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            serial_number: '',
            productno: '',
            location: '',
            capsule: '',
            lastlocalip: '',
            emfitsn: '',
            emfitpin: '',
            emfitpw: '',
            capsules: '',
        }
    }

    componentWillMount(){
        axios.all([
            axios.get("/api/hotels/" , {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
            }}),
            axios.get("/api/devices/sn/" + this.props.sn , {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        ]).then(axios.spread((res1, res2) => {
            if (res1.data.success && res2.data.success) {
                // 加载location数据
                let locations = [];
                res1.data.hotels.map((item, index) => { 
                    return(locations.push({ key: index, text: item.name, value: item.id }))
                })
                let device = res2.data.device;
                this.setState({
                    serial_number: device.serial_number,
                    productno: device.productno,
                    location: device.location,
                    capsule: device.capsule,
                    lastlocalip: device.lastlocalip,
                    emfitsn: device.emfitsn,
                    emfitpin: device.emfitpin,
                    emfitpw: device.emfitpw,
                    locations: locations
                },()=>{
                    axios.get("/api/rooms/hotel/" + device.location + "?offset=0&limit=50" , {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.usertoken,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(res => {
                        // 加载location数据
                        let capsules = [];
                        res.data.rooms.map((item, index) => { 
                            return(capsules.push({ key: index, text: item.roomid, value: item._id }))
                        })
                        this.setState({
                            loaded: true,
                            capsules: capsules
                        })
                    }).catch(err => {
                        console.log(err);
                    });                    
                })
            }else{

            }
        }));
    }

    onChange = (e, { name, value }) => this.setState({ [name]:  value })
    
    render() {    
        const { serial_number, productno, location, capsule, lastlocalip, emfitpin, emfitpw, emfitsn, lat, lng, locations, capsules } = this.state;
        const values = { serial_number, productno, location, capsule, lastlocalip, emfitpin, emfitpw, emfitsn, lat, lng, locations, capsules };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Card fluid color='red'>
                            <Card.Content>
                            <h3><Icon name="info"></Icon>&nbsp;&nbsp;Device info</h3>
                            </Card.Content>
                            <Card.Content>
                            {this.state.loaded ? (    
                                <Form style={{ textAlign: 'left' }}>
                                    <Form.Group widths='equal'>
                                        <Form.Input fluid icon='plug' iconPosition='left'  type="text" name="serial_number"
                                            defaultValue={values.serial_number} label='Serial Number' disabled/>
                                        <Form.Input fluid icon='plug' iconPosition='left'  type="text" name="productno"
                                            defaultValue={values.productno} label='Production No' disabled/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>   
                                        <Form.Dropdown label='Location' selection placeholder='Choose Location' name="location" 
                                            options={values.locations} value={values.location} disabled/>
                                        <Form.Dropdown label='Capsule' selection placeholder='Choose Capsule' name="capsule" 
                                            options={values.capsules} value={values.capsule} disabled/>
                                    </Form.Group>
                                    <Form.Group widths='equal'>    
                                        <Form.Input label='Last Local IP' fluid icon='ellipsis horizontal' iconPosition='left'  type="text" name="lastlocalip"
                                            defaultValue={values.lastlocalip} disabled/>
                                        <Form.Input label='Emfit PIN' fluid icon='plug' iconPosition='left'  type="text" name="emfitpin"
                                            defaultValue={values.emfitpin} disabled/>    
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input label='Emfit PW' fluid icon='plug' iconPosition='left'  type="text" name="emfitpw"
                                            defaultValue={values.emfitpw} disabled/>
                                        <Form.Input label='Emfit SN' fluid icon='plug' iconPosition='left'  type="text" name="emfitsn"
                                            defaultValue={values.emfitsn} disabled/>
                                    </Form.Group>
                                </Form>
                            ) : (
                                <Segment>
                                    <Icon name='th' size='huge' color='red' ></Icon>
                                    No Device!
                                </Segment>
                            )}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => { this.props.history.push('/ippanel') }}>
                                    <Icon name="backward"></Icon>Back
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(DeviceInfo);
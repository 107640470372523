/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors  : jiangbotao
 * @LastEditTime : 2019-12-23 21:10:04
 * @FilePath: \rem-rest-api\client\src\components\dataviz\devicetable\page\DeviceEdit.js
 */
/**
 * DeviceEdit
 * Device具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
// import { Map, TileLayer, Marker } from 'react-leaflet';
// import L from "leaflet";
import './DeviceEdit.css';

// delete L.Icon.Default.prototype._getIconUrl;
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
//     iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
//     shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
// });

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class DeviceEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            serial_number: null,
            productno: null,
            location: null,
            capsule: null,
            lastlocalip: null,
            emfitid: null,
            emfitsn: null,
            emfitpin: null,
            emfitpw: null,
            lat: 0,
            lng: 0,
            locations: null,
            capsules: null,
        }
    }

    componentWillMount() {
        axios.all([
            axios.get("/api/hotels/", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }),
            axios.get("/api/devices/" + this.props.id, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            })
        ]).then(axios.spread((res1, res2) => {
            if (res1.data.success && res2.data.success) {
                // 加载location数据
                let locations = [];
                res1.data.hotels.map((item, index) => {
                    return (locations.push({ key: index, text: item.name, value: item.id }))
                })
                let device = res2.data.device;
                this.setState({
                    loaded: true,
                    serial_number: device.serial_number,
                    productno: device.productno,
                    location: device.location,
                    capsule: device.capsule,
                    lastlocalip: device.lastlocalip,
                    emfitsn: device.emfitsn,
                    emfitid: device.emfitid,
                    emfitpin: device.emfitpin,
                    emfitpw: device.emfitpw,
                    lat: device.lat,
                    lng: device.lng,
                    locations: locations
                }, () => {
                    axios.get("/api/rooms/hotel/" + device.location + "?offset=0&limit=50", {
                        headers: {
                            'Authorization': 'Bearer ' + localStorage.usertoken,
                            'Content-Type': 'application/x-www-form-urlencoded'
                        }
                    }).then(res => {
                        // 加载location数据
                        let capsules = [];
                        res.data.rooms.map((item, index) => {
                            return (capsules.push({ key: index, text: item.label, value: item._id }))
                        })
                        this.setState({
                            capsules: capsules
                        })
                    }).catch(err => {
                        console.log(err);
                    });
                })
            }
        }));
    }

    successNotify = () => toast.success("Device Update Successfully...", {
        onClose: () => this.props.history.push('/devicepanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]: value })

    update() {
        axios.patch("/api/devices/" + this.props.id, {
            serial_number: this.state.serial_number,
            productno: this.state.productno,
            hostproductno: this.state.hostproductno,
            location: this.state.location,
            capsule: this.state.capsule,
            lastlocalip: this.state.lastlocalip,
            emfitid: this.state.emfitid,
            emfitsn: this.state.emfitsn,
            emfitpin: this.state.emfitpin,
            emfitpw: this.state.emfitpw,
            lat: this.state.lat,
            lng: this.state.lng
        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
        }).then(res => {
            this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        const { serial_number, productno, location, capsule, lastlocalip, emfitid, emfitpin, emfitpw, emfitsn, lat, lng, locations, capsules } = this.state;
        const values = { serial_number, productno, location, capsule, lastlocalip, emfitid, emfitpin, emfitpw, emfitsn, lat, lng, locations, capsules };
        // const position = [this.state.lat, this.state.lng]
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Device Update</h3>
                            </Card.Content>
                            <Card.Content>
                                {this.state.loaded ? (
                                    <Form style={{ textAlign: 'left' }}>
                                        <Form.Group widths='equal'>
                                            <Form.Input fluid icon='plug' iconPosition='left' type="text" name="serial_number"
                                                defaultValue={values.serial_number} onChange={this.onChange} required label='Serial Number' />
                                            <Form.Input fluid icon='plug' iconPosition='left' type="text" name="productno"
                                                defaultValue={values.productno} onChange={this.onChange} required label='Production No' />
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Dropdown label='Location' selection placeholder='Choose Location' name="location"
                                                options={values.locations} value={values.location} onChange={this.onChange} />
                                            <Form.Dropdown label='Capsule' selection placeholder='Choose Capsule' name="capsule"
                                                options={values.capsules} value={values.capsule} onChange={this.onChange} />
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Input label='Last Local IP' fluid icon='ellipsis horizontal' iconPosition='left' type="text" name="lastlocalip"
                                                defaultValue={values.lastlocalip} onChange={this.onChange} required />
                                            <Form.Input label='Emfit PIN' fluid icon='plug' iconPosition='left' type="text" name="emfitpin"
                                                defaultValue={values.emfitpin} onChange={this.onChange} required />
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Input label='Emfit PW' fluid icon='plug' iconPosition='left' type="text" name="emfitpw"
                                                defaultValue={values.emfitpw} onChange={this.onChange} required />
                                            <Form.Input label='Emfit SN' fluid icon='plug' iconPosition='left' type="text" name="emfitsn"
                                                defaultValue={values.emfitsn} onChange={this.onChange} required />
                                        </Form.Group>
                                        <Form.Input label='Emfit ID' fluid icon='plug' iconPosition='left' type="text" name="emfitid"
                                            defaultValue={values.emfitid} onChange={this.onChange} required />
                                        {/* <Form.Group widths='equal'>
                                        <Form.Input label='Latitude' fluid icon='globe' iconPosition='left'  type="text" name="lat"
                                            defaultValue={values.lat} onChange={this.onChange} required />
                                        <Form.Input label='Longitude' fluid icon='globe' iconPosition='left'  type="text" name="lng"
                                            defaultValue={values.lng} onChange={this.onChange} required />
                                    </Form.Group> */}
                                        {/* {(this.state.lat != null) ? (
                                        <Map style={{ width: '100%', height: '500px' }} center={position} zoom={18} zoomControl={true}>
                                            <TileLayer attribution='&copy;OpenStreetMap contributors' url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                                            <Marker position={position}></Marker>
                                        </Map>
                                    ):('')} */}
                                    </Form>
                                ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(DeviceEdit);
/**
 * UserEdit
 * User具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Label, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './UserEdit.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class UserEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            role: '',
            active: true,
        }
    }

    componentDidMount(){
        axios.get("/api/auth/user/" + this.props.id ,{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const user = res.data.user;
                this.setState({
                    username: user.username,
                    email: user.email,
                    role: user.role,
                    active: user.active
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("User Update Successfully...", {
        onClose: () => this.props.history.push('/userpanel')
    });

    onChange = (e, { value }) => {
        this.setState({ value });
    }

    roleChange = (e, { value }) => {
        console.log(e);
        this.setState({
            role: value
        });
    }

    activeChange = (e, { value }) => {
        this.setState({
            active: value
        });
    }

    update() {
        axios.patch("/api/auth/user/" + this.props.id, {
            role: this.state.role,
            isActive: this.state.active
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
            }).then(res => {
                this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {
        const roleOptions = [
            { key: 1, text: 'Admin', value: 'Admin' },
            { key: 2, text: 'Manager', value: 'Manager' },
            { key: 3, text: 'Guest', value: 'Guest' },
        ]
        const activeOptions = [
            { key: 1, text: '是(Y)', value: true },
            { key: 2, text: '否(N)', value: false },
        ]
        
        const { email, username, role, active } = this.state;
        const values = { email, username, role, active };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;UserInfo Update</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left'}}>
                                    <Label pointing='below' as='a' color='green'>Your Email</Label>
                                    <Form.Input fluid icon='mail' iconPosition='left'  type="email" name="email"
                                        defaultValue={values.email} onChange={this.onChange} required disabled />
                                    <Label pointing='below' as='a' color='green'>Your Username</Label>
                                    <Form.Input fluid icon='user' iconPosition='left' type="text" name="username"
                                        defaultValue={values.username} onChange={this.onChange} required disabled/>
                                    <Form.Dropdown label='Role' selection placeholder='Choose Role' name="role" 
                                        options={roleOptions} onChange={this.roleChange} value={values.role}/>
                                    <Form.Dropdown label='Active' selection placeholder='Choose Active'  name="active" 
                                        options={activeOptions} onChange={this.activeChange} value={ values.active }/>
                                </Form>
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(UserEdit);
/**
 * 主要用于显示EmfitSummary Model数据
 * 实现了数据的展示与删除功能
 * 数据展示：
 * 数据删除：
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Button, Icon, Dropdown, Header, Divider } from 'semantic-ui-react';
import { DatesRangeInput } from 'semantic-ui-calendar-react';
import EmfitCrumb from '../../../menu/crumb/EmfitCrumb';
import axios from 'axios';
import Chart from 'react-apexcharts';
import moment from 'moment';
import './EmfitShow.css';

class EmfitShow extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            apidata: null,

            devices: null,
            curdevice: null,
            datesRange: '',
            charttype: 'bar',
        }
    }

    componentWillMount() {
        axios.get("/api/data/summary/devices",{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const devices = [];
                res.data.result.map((device, index) => { 
                    return(devices.push({key: index, text: device, value: device}))
                })
                this.setState({
                    devices: devices
                })
            }
        }).catch(err => {
            console.log(err);
        });
    }
    
    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
          this.setState({ [name]: value });
        }
        
    }

    deviceChange = (e, { value }) => {
        this.setState({
            curdevice: value
        });
    }

    showChart() {
        this.setState({
            loaded: false,
        }) 
        if (this.state.curdevice != null && this.state.datesRange != null) {
            var dt_from, dt_to;
            dt_from = this.state.datesRange.split(' - ')[0]
            dt_to = this.state.datesRange.split(' - ')[1]
            axios.get("/api/data/summary/devices/" + this.state.curdevice + '/' + dt_from + '/' + dt_to ,{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(res => {
                if (res.data.success) {
                    this.setState({
                        apidata: res.data.result
                    })
                    if (res.data.result.length > 0) {
                        this.setState({
                            loaded: true,
                        }) 
                    }
                }
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        const { devices, charttype } = this.state;
        const values = { devices, charttype };
        var avg_options, avg_series = [];
        if (this.state.loaded) {
            avg_options = {
                xaxis: {
                    categories: ['AVG_HR', 'AVG_RR', 'AVG_ACT']
                },
                dataLabels: {
                    enabled: true,
                }
            };
            this.state.apidata.map((emfit, index) => { 
                return(avg_series.push({ name: moment(emfit.createdAt).format("YYYY-MM-DD"), data: [emfit.avg_hr, emfit.avg_rr, emfit.avg_act]}))
            })
        }
        return (
            <Grid container stackable >
                <Grid.Row>
                    <Grid.Column>
                        <EmfitCrumb></EmfitCrumb>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={6}>
                        <Dropdown selection placeholder='Choose Device' name="curdevice" 
                                            options={values.devices} onChange={this.deviceChange}/>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <DatesRangeInput name="datesRange" placeholder="From - To" style={{ width: '100%' }}
                                localization='zh_cn' dateFormat='MM-DD-YYYY' closable={true}
                                value={this.state.datesRange} iconPosition="left" onChange={this.handleChange}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button floated='right' icon labelPosition='left' negative size='small' onClick={(e, props, id) => this.showChart()}>
                            <Icon name='chart bar outline'></Icon>Visualization
                        </Button>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column><Divider></Divider></Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                    {this.state.loaded ? (
                        <Chart options={avg_options} series={avg_series} type='bar' width={'100%'} height={500} />
                        ) : (
                        <Header as='h2' icon textAlign='center'>
                            <Icon name='pie chart' color='blue' circular loading/>
                            <Header.Content>No data for Charting</Header.Content>
                        </Header>
                    )}
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(EmfitShow);
/**
 * 主要用于显示Room Model数据
 * 实现了数据的展示与删除功能
 * 数据展示：
 * 数据删除：
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Table, Icon, Pagination, Input, Button, Confirm } from 'semantic-ui-react';
import HotelCrumb from '../../../menu/crumb/HotelCrumb';
import axios from 'axios';
import jwt_decode from 'jwt-decode';
import _ from 'lodash';
import './RoomTable.css';

class RoomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            apidata: null,
            searchText: '',             // 查询字符串
            searchValue: 'Search...',   // 数据查询显示字符串
            column: null,
            direction: null,
            pageItemCount: 15,
            activePage: 1,
            boundaryRange: 0,
            siblingRange: 1,
            showEllipsis: true,
            showFirstAndLastNav: true,
            showPreviousAndNextNav: true,
            totalPages: 1,
            confirmOpen: false,         // 删除弹出框
            curid: null
        }
    }

    componentWillMount() {
        // 默认显示第1页
        this.getUserList(0, '');
    }

    getUserList = (offset, searchTxt) => {
        this.setState({
            loading: true,
        });
        // 获取用户信息时将token添加在headers中发送
        axios.get("/api/rooms?search=" + searchTxt + "&offset=" + offset + "&limit=" + this.state.pageItemCount , {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            this.setState({
                loading: false,
                apidata: res.data.rooms,
                totalPages: Number.isInteger(res.data.count / this.state.pageItemCount) ?  Math.floor(res.data.count / this.state.pageItemCount) : Math.floor(res.data.count / this.state.pageItemCount) + 1,  
            });
        }).catch(err => {
            console.log(err);
        });
    }

    // pagination时处理翻页
    setActivePage = (activePage) => {
        this.setState({ activePage: activePage });
        this.getUserList(( activePage - 1) * this.state.pageItemCount , '');
    }

    handlePaginationChange = (e, { activePage }) => {
        this.setActivePage(activePage);
    }
    
    handleCancel = () => {
        this.setState({ confirmOpen: false });
    }

    // 处理Confirm的Confirm事件
    // 本质上是改变状态
    handleConfirm = () => {
        axios.delete("/api/rooms/"+ this.state.curid, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                this.setState({ confirmOpen: false });
                this.getUserList(( this.state.activePage - 1) * this.state.pageItemCount, '');
            }
        }).catch(err => {
            console.log(err);
        });
    } 

    // 删除按钮触发事件
    deleteClick = (id) => {
        this.setState({ confirmOpen: true, curid: id });
    }

    // 编辑按钮触发事件
    editClick(id) {
        this.props.history.push('/roomedit/' + id);
    }

    handleInputChange = (e) => {
        this.setState({ searchText: e.target.value });
    }

    searchClick = (e) => {
        this.getUserList(0, this.state.searchText);
    }
    
    // 表格按字段排序
    handleSort = clickedColumn => () => {
        const { column, apidata, direction } = this.state
        if (column !== clickedColumn) {
            this.setState({
                column: clickedColumn,
                apidata: _.sortBy(apidata, [clickedColumn]),
                direction: 'ascending',
          })
          return
        }
        this.setState({
            apidata: apidata.reverse(),
            direction: direction === 'ascending' ? 'descending' : 'ascending',
        })
    }
    
    render() {
        const { confirmOpen, column, direction, activePage, boundaryRange, siblingRange, showEllipsis, showFirstAndLastNav, showPreviousAndNextNav, totalPages } = this.state
        // 判断是否为Admin，以此确定是否有删除和修改属性的权力
        const isAdmin = jwt_decode(localStorage.usertoken).role === 'Admin' ? true : false;
        return (
            <Grid >
                <Grid.Row>
                    <Grid.Column>
                        <HotelCrumb></HotelCrumb>
                    </Grid.Column>
                </Grid.Row>
                {isAdmin ? (
                <Grid.Row>
                    <Grid.Column width={12}>
                        <Input action={{ icon: "search", color: 'blue', onClick: () => this.searchClick() }} style={{ width: '60%' }}
                            placeholder={ this.state.searchValue } onChange={this.handleInputChange}/>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Button floated='right' icon labelPosition='left' circular primary size='small' onClick={(e, props, id) => this.props.history.push('/roomaddpanel/')}>
                            <Icon name="add"></Icon>Add Capsule
                        </Button>
                    </Grid.Column>
                </Grid.Row>):('')}
                <Grid.Row>
                    <Grid.Column>
                        <Confirm open={confirmOpen} content='Are you sure?' cancelButton='Cancel' confirmButton="Delete"
                            onCancel={this.handleCancel} onConfirm={this.handleConfirm} />
                        <Table color='purple' celled selectable striped sortable>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>ID</Table.HeaderCell>        
                                    <Table.HeaderCell>Capsule Label</Table.HeaderCell>
                                    <Table.HeaderCell>Product ID</Table.HeaderCell>
                                    <Table.HeaderCell sorted={column === 'hotelname' ? direction : null} onClick={this.handleSort('hotelname')}>Location</Table.HeaderCell>
                                    <Table.HeaderCell>CapsuleType</Table.HeaderCell>
                                    {isAdmin ? (<Table.HeaderCell>Actions</Table.HeaderCell>) : null}
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>{this.state.loading ? (
                                <Table.Row>
                                    <Table.Cell colSpan='6' textAlign='center'>
                                        <Icon name='sync alternate' size='huge' color="red" loading></Icon>
                                    </Table.Cell>
                                </Table.Row>
                            ) : (
                                this.state.apidata.map((room, index) => {
                                    return (
                                        <Table.Row key={index}>
                                            <Table.Cell collapsing>{index+1}</Table.Cell>
                                            <Table.Cell>{room.label}</Table.Cell>
                                            <Table.Cell>{room.roomid}</Table.Cell>
                                            <Table.Cell>{room.hotelname}</Table.Cell>
                                            <Table.Cell>{room.capsuletype ? room.capsuletype : ''}</Table.Cell>
                                            {isAdmin ? (<Table.Cell collapsing >
                                                <Button primary circular icon="edit" onClick={(e, props, id) => this.editClick(room._id)}  ></Button>
                                                <Button negative circular icon="delete" onClick={(e, props, id) => this.deleteClick(room._id)}></Button>
                                            </Table.Cell>) : ('')}
                                        </Table.Row>
                                    )
                                })
                            )}</Table.Body>
                            <Table.Footer>
                                <Table.Row>
                                    <Table.HeaderCell colSpan='6'>
                                    <Pagination
                                        activePage={activePage}
                                        boundaryRange={boundaryRange}
                                        onPageChange={this.handlePaginationChange}
                                        size='mini'
                                        siblingRange={siblingRange}
                                        totalPages={totalPages}
                                        ellipsisItem={showEllipsis ? undefined : null}
                                        firstItem={showFirstAndLastNav ? undefined : null}
                                        lastItem={showFirstAndLastNav ? undefined : null}
                                        prevItem={showPreviousAndNextNav ? undefined : null}
                                        nextItem={showPreviousAndNextNav ? undefined : null}
                                    />
                                    </Table.HeaderCell>
                                </Table.Row>
                            </Table.Footer>
                        </Table>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(RoomTable);
/**
 * UserEdit
 * User具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './DeviceAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class DeviceAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: ' ',
            serial_number: null,
            productno: null,
            location: null,
            capsule: null,
            lastlocalip: null,
            emfitid: null,
            emfitsn: null,
            emfitpin: null,
            emfitpw: null,
            lat: 0,
            lng: 0,
            locations: null,
            capsules: null
        }
    }

    componentDidMount() {
        axios.get("/api/hotels/", {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const locations = [];
                res.data.hotels.map((hotel, index) => {
                    return (locations.push({ key: index, text: hotel.name, value: hotel.id }))
                })
                this.setState({
                    locations: locations
                })
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("Device Add Successfully...", {
        onClose: () => this.props.history.push('/devicepanel')
    });

    errNotify = (msg) => toast.error(msg, {});

    // Input控件输入数据
    onChange = (e, { name, value }) => this.setState({ [name]: value })

    onLocationChange = (e, { name, value }) => {
        this.setState({
            location: value
        }, () => {
            axios.get("/api/rooms/hotel/" + value + "?offset=0&limit=50", {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }).then(res => {
                // 加载location数据
                let capsules = [];
                res.data.rooms.map((item, index) => {
                    return (capsules.push({ key: index, text: item.label, value: item._id }))
                })
                this.setState({
                    capsules: capsules
                })
            }).catch(err => {
                console.log(err);
            });
        })
    };

    formValid = () => {
        if (this.state.serial_number === null) {
            this.errNotify('Serial No. is Null');
            return false;
        }
        if (this.state.productno === null) {
            this.errNotify('Product No. is Null');
            return false;
        }
        if (this.state.location === null) {
            this.errNotify('Location is Null');
            return false;
        }
        if (this.state.capsule === null) {
            this.errNotify('Capsule is Null');
            return false;
        }
        return true
    }

    add() {
        if (this.formValid()) {
            axios.post("/api/device/register", {
                name: this.state.name,
                serial_number: this.state.serial_number,
                productno: this.state.productno,
                location: this.state.location,
                capsule: this.state.capsule,
                lastlocalip: this.state.lastlocalip,
                emfitid: this.state.emfitid,
                emfitsn: this.state.emfitsn,
                emfitpin: this.state.emfitpin,
                emfitpw: this.state.emfitpw,
                lat: this.state.lat,
                lng: this.state.lng,
                max_ppl: 0,
            }, {
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken
                }
            }).then(res => {
                this.successNotify();
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        const { locations, capsules } = this.state;
        const values = { locations, capsules };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="podcast"></Icon>&nbsp;&nbsp;Add Device</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left' }}>
                                    <Form.Group widths='equal'>
                                        <Form.Input label='Serial Number' fluid icon='plug' iconPosition='left' type="text" name="serial_number"
                                            onChange={this.onChange} required />
                                        <Form.Input label='Production No' fluid icon='plug' iconPosition='left' type="text" name="productno"
                                            onChange={this.onChange} required />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Dropdown label='Location' selection placeholder='Choose Location' name="location"
                                            options={values.locations} onChange={this.onLocationChange} />
                                        <Form.Dropdown label='Capsule' selection placeholder='Choose Capsule' name="capsule"
                                            options={values.capsules} onChange={this.onChange} />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input label='Last Local IP' fluid icon='ellipsis horizontal' iconPosition='left' type="text" name="lastlocalip"
                                            onChange={this.onChange} required />
                                        <Form.Input label='Emfit PIN' fluid icon='plug' iconPosition='left' type="text" name="emfitpin"
                                            onChange={this.onChange} required />
                                    </Form.Group>
                                    <Form.Group widths='equal'>
                                        <Form.Input label='Emfit PW' fluid icon='plug' iconPosition='left' type="text" name="emfitpw"
                                            onChange={this.onChange} required />
                                        <Form.Input label='Emfit SN' fluid icon='plug' iconPosition='left' type="text" name="emfitsn"
                                            onChange={this.onChange} required />
                                    </Form.Group>
                                    <Form.Input label='Emfit ID' fluid icon='plug' iconPosition='left' type="text" name="emfitid"
                                        onChange={this.onChange} required />
                                </Form>
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(DeviceAdd);
/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:36:31
 * @FilePath: \rem-rest-api\client\src\components\dataviz\hoteltable\page\HotelAdd.js
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './CapsuleTypeAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class CapsuleTypeAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            version: '',
            maxpeople: '',
            height: '',
            width: '',
            length: ''
        }
    }

    componentDidMount(){  }

    /**
     * 成功通知
     */
    successNotify = () => toast.success("CapsuleType Add Successfully...", {
        onClose: () => this.props.history.push('/capsuletypepanel')
    });

    /**
     * 错误通知
     */
    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER
        });
    };

    /**
     * Form.Input内容变化的处理函数
     */
    onChange = (e, { name, value }) => this.setState({ [name]:   value })

    add() {
        if (this.state.name === '') {
            this.errNotify('Type Name is Null');
        } else {
            axios.post("/api/capsuletypes/", {
                name: this.state.name.trimRight(),
                version: this.state.version,
                maxpeople: this.state.maxpeople,
                width: this.state.width,
                height: this.state.height,
                length: this.state.length
            },{
                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken
                }
            }).then(res => {
                this.successNotify();
            }).catch(err => {
                console.log(err);
            });   
        }
    }
    
    render() {    
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="warehouse"></Icon>&nbsp;&nbsp;Add Capsule Type</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left'}}>
                                    <Form.Input label='Name' fluid icon='osi' iconPosition='left'  type="text" name="name"
                                       onChange={this.onChange} required />
                                    <Form.Input label='Version' fluid icon='fork' iconPosition='left'  type="text" name="version"
                                       onChange={this.onChange} required />
                                    <Form.Input label='MaxPeople' fluid icon='users' iconPosition='left'  type="text" name="maxpeople"
                                       onChange={this.onChange} required />
                                    <Form.Input label='Width' fluid icon='text width' iconPosition='left'  type="text" name="width"
                                       onChange={this.onChange} required />
                                    <Form.Input label='Length' fluid icon='xing' iconPosition='left'  type="text" name="length"
                                       onChange={this.onChange} required />
                                    <Form.Input label='Height' fluid icon='text height' iconPosition='left'  type="text" name="height"
                                       onChange={this.onChange} required />
                                </Form>
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(CapsuleTypeAdd);
/**
 * RoomPanel
 * Room展示面板
 */
import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import RoomTable from './page/RoomTable';
import SidebarMenu from '../../menu/SidebarMenu';

class RoomPanel extends Component{
    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <RoomTable></RoomTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default RoomPanel;
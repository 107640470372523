/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:55:04
 * @FilePath: \rem-rest-api\client\src\components\menu\TopMenu.js
 */
/**
 * 系统顶部菜单栏设计
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Menu, Dropdown, Icon } from 'semantic-ui-react';
import jwt_decode from 'jwt-decode';
import './TopMenu.css';

class TopMenu extends Component{
    /**
     * 判断是否登录, 首先判断usertoken是否存在
     * 然后判断其是否在有效期内
     */
    isAuthorization() {
        if (localStorage.usertoken) {
            const decoded = jwt_decode(localStorage.usertoken);
            if (Date.now() / 1000 < decoded.exp) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    }

    /**
     * 系统登出后清除token并跳转到首页
     * @param {*} e 
     */
    logOut(e) {
        e.preventDefault();
        if (localStorage.usertoken) {
            localStorage.removeItem('usertoken');
            this.props.history.push('/');
        }
    }

    /**
     * 当usertoken存在且时间在有效期时才能显示用户名
     */
    triggerprofile(e) {
        if (this.isAuthorization()) {
            const decoded = jwt_decode(localStorage.usertoken);
            return <span><Icon name='fax'/>{ decoded.username }</span>
        } else {
            return <span><Icon name='fax'/>Dashboard</span>
        }
    }

    render() {
        // 未登录时的下拉菜单
        const loginRegLink = (
            <Menu.Menu position="right">
                <Dropdown pointing className='link item' trigger={ <span><Icon name='user'/> Guest</span> }>
                    <Dropdown.Menu>
                        <Dropdown.Item as="a" href="/" name="home" icon='home' text='Index'/>
                        <Dropdown.Divider />
                        <Dropdown.Item as="a" href="/login" name="login" icon='sign in' text='Sign in' />
                        <Dropdown.Divider />
                        <Dropdown.Item as="a" href="/api/docs" name="login" icon='code branch' text='API'/>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>
        )
        const userLink = (
            <Menu.Menu position="right">
                <Dropdown pointing className='link item' trigger={ this.triggerprofile() }>
                    <Dropdown.Menu>
                        <Dropdown.Item as="a" href="/" name="home" icon='home' text='Index'/>
                        <Dropdown.Divider />
                        <Dropdown.Item as="a" href="/userpanel" name="profile" icon="braille" text='Dashboard' />
                        <Dropdown.Item as="a" href="/api/docs" name="login" icon='code branch' text='API'/>
                        <Dropdown.Divider />
                        <Dropdown.Item as="a" href="/" name="logout" onClick={this.logOut.bind(this)} icon='log out' text='Logout'/>
                    </Dropdown.Menu>
                </Dropdown>
            </Menu.Menu>            
        )

        return (
            <Menu fixed="top">
                <Menu.Item>
                    <a href='/'><Icon size='big' name="compass" color="red" loading/></a>
                </Menu.Item>
                <Menu.Item>
                    <h2>REM.</h2>
                </Menu.Item>
                { this.isAuthorization() ? userLink : loginRegLink }
            </Menu>
        )
    }
}
export default withRouter(TopMenu); // 使用withRouter才可以使用this.props.history.push
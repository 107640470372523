/**
 * HotelEditPanel
 * Hotel编辑面板
 */
import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import HotelEdit from './page/HotelEdit';
import SidebarMenu from '../../menu/SidebarMenu';

class HotelEditPanel extends Component{
    render() {
        const { match: { params } } = this.props;
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <HotelEdit id={params.id}></HotelEdit>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}
export default HotelEditPanel;
/*
 * @Author: jiangbotao
 * @Date: 2019-12-03 00:24:55
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-03 00:25:13
 * @FilePath: \rem-rest-api\client\src\components\dataviz\capsuletype\HotelEditPanel.js
 */
import React, { Component } from 'react';
import { Grid, Segment } from 'semantic-ui-react';
import CapsuleTypeEdit from './page/CapsuleTypeEdit';
import SidebarMenu from '../../menu/SidebarMenu';

class CapsuleTypeEditPanel extends Component{
    render() {
        const { match: { params } } = this.props;
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <CapsuleTypeEdit id={params.id}></CapsuleTypeEdit>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        );
    }
}
export default CapsuleTypeEditPanel;
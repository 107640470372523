import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import EmfitShow from './page/EmfitShow';
import SidebarMenu from '../../menu/SidebarMenu';

class EmfitShowPanel extends Component{

    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <EmfitShow></EmfitShow>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default EmfitShowPanel;
/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:31:44
 * @FilePath: \rem-rest-api\client\src\components\dataviz\hoteltable\page\HotelEdit.js
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './HotelEdit.css';


toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class HotelEdit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            name: '',
            remHubLocalIp: '',
            remHubPublicIp: '',
        }
    }

    componentDidMount() {
        axios.get("/api/hotels/" + this.props.id, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const hotel = res.data.hotel;
                this.setState({
                    loaded: true,
                    name: hotel.name,
                    remHubLocalIp: hotel.remHubLocalIp,
                    remHubPublicIp: hotel.remHubPublicIp,
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("Location Update Successfully...", {
        onClose: () => this.props.history.push('/hotelpanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]: value })

    update() {
        axios.patch("/api/hotels/" + this.props.id, {
            name: this.state.name.trimRight(),
            remHubLocalIp: this.state.remHubLocalIp,
            remHubPublicIp: this.state.remHubPublicIp,

        }, {
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
        }).then(res => {
            this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }

    render() {
        const values = this.state;
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;Location Update</h3>
                            </Card.Content>
                            <Card.Content>
                                {this.state.loaded ? (

                                    <Form style={{ textAlign: 'left' }}>
                                        <Form.Input label='Location Name' fluid icon='building' iconPosition='left' type="text" name="name"
                                            defaultValue={values.name} onChange={this.onChange} required />
                                        <Form.Input label='REM-hub Local IP' fluid icon='plug' iconPosition='left' type="text" name="remHubLocalIp"
                                            defaultValue={values.remHubLocalIp} onChange={this.onChange} required />
                                        <Form.Input label='REM-hub Public IP' fluid icon='plug' iconPosition='left' type="text" name="remHubPublicIp"
                                            defaultValue={values.remHubPublicIp} onChange={this.onChange} required />
                                    </Form>

                                ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(HotelEdit);
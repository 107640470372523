/**
 * Login Page
 * 登录页面
 * chiangbt@geoportal.cn
 */
import React, { Component } from 'react';
import { login } from '../utils/UserFunctions';
import { Grid, Divider, Card, Container, Form, Button, Icon, Label } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

class LoginPanel extends Component{
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
        }
        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    errNotify = () => {
        toast.error("登录用户名或密码不正确", {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.setState({
                password: ''
            })
        });
    };

    successNotify = () => {
        toast.info("User SignIn Successfully...", {
            autoClose: 800,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER,
            onClose: () => this.props.history.push('/hotelpanel')
        });
    }
    
    onChange(e) {
        this.setState({ [e.target.name]: e.target.value })
    }

    onSubmit(e) {
        e.preventDefault();

        const user = {
            email: this.state.email,
            password: this.state.password
        }
        // 使用Utils中UserFunctions的login函数登录
        login(user).then(res => {
            if (res.success) { 
                // 跳转到userpanel中
                this.successNotify();
            } else {
                this.errNotify();
            }
        })
    }

    render() {
        return (
            <Grid textAlign='center' verticalAlign='middle' style={{ height: '100%', marginTop:'100px', marginLeft:'unset', marginRight:'unset' }} >
                <Grid.Column mobile={16} tablet={8} computer={5}>
                    <Card color='red' style={{ width: 'unset'}}>
                        <Card.Content>
                            <h3><Icon name="sign in"></Icon>&nbsp;&nbsp;用户登录</h3>
                        </Card.Content>
                        <Card.Content>
                            <Form onSubmit={this.onSubmit} style={{ textAlign: 'left' }}>
                                <Label pointing='below' as='a' color='blue'>Your Email</Label>
                                <Form.Input fluid icon='user' iconPosition='left' type="text"
                                    name="email" value={this.state.email} onChange={this.onChange}
                                    required autoFocus placeholder='E-mail' />
                                <Label pointing='below' as='a' color='blue'>Your Password</Label>
                                <Form.Input fluid icon='lock' name="password"
                                    iconPosition='left' placeholder='Password' value={this.state.password}
                                    onChange={this.onChange} required type='password'/>
                                <Container textAlign="center">
                                    <Button type="submit" color='red'>
                                        SignIn
                                    </Button>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <Button primary type="button" onClick={() => this.props.history.push('/register')}>
                                        SignUp
                                    </Button>
                                </Container>
                            </Form>
                            <Container textAlign='center'>  
                                <Divider horizontal>OR</Divider>
                                <Button type='button' color='blue' onClick={this.gotoDetailPage.bind(this)} >
                                    <Icon name='github'/> Github
                                </Button>
                                <Button type='text' color='green'>
                                    <Icon name='weixin' /> Weixin
                                </Button>
                            </Container>
                        </Card.Content>
                    </Card>
                </Grid.Column>
            </Grid>
        );
    }

    gotoDetailPage() {
        // window.location.href = "/"
        this.props.history.push("/");
    }
}
export default LoginPanel;
/*
 * @Author: jiangbotao
 * @Date: 2019-12-03 00:26:21
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:35:57
 * @FilePath: \rem-rest-api\client\src\components\dataviz\capsuletype\page\CapsuleTypeEdit.js
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './CapsuleTypeEdit.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class CapsuleTypeEdit extends Component{
    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            name: '',
        }
    }

    componentDidMount(){
        axios.get("/api/capsuletypes/" + this.props.id ,{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const capsuletype = res.data.capsuletype;
                this.setState({
                    loaded: true,
                    name: capsuletype.name,
                    version: capsuletype.version,
                    maxpeople: capsuletype.maxpeople,
                    width: capsuletype.width,
                    height: capsuletype.height,
                    length: capsuletype.length
                });
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("CapsuleType Update Successfully...", {
        onClose: () => this.props.history.push('/capsuletypepanel')
    });

    onChange = (e, { name, value }) => this.setState({ [name]:   value })

    update() {
        axios.patch("/api/capsuletypes/" + this.props.id, {
            name: this.state.name.trimRight(),
            version: this.state.version,
            maxpeople: this.state.maxpeople,
            width: this.state.width,
            height: this.state.height,
            length: this.state.length
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
        }).then(res => {
            this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        const { name, version, maxpeople, height, length, width } = this.state;
        const values = { name, version, maxpeople, height, length, width };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="refresh"></Icon>&nbsp;&nbsp;CpasuleType Update</h3>
                            </Card.Content>
                            <Card.Content>
                                {this.state.loaded ? (
                                    <Form style={{ textAlign: 'left' }}>
                                        <Form.Input label='Name' fluid icon='osi' iconPosition='left'  type="text" name="name"
                                            defaultValue={values.name} onChange={this.onChange} required />
                                        <Form.Input label='Version' fluid icon='fork' iconPosition='left'  type="text" name="version"
                                            defaultValue={values.version} onChange={this.onChange} required />
                                        <Form.Input label='MaxPeople' fluid icon='users' iconPosition='left'  type="text" name="maxpeople"
                                            defaultValue={values.maxpeople} onChange={this.onChange} required />
                                        <Form.Input label='Width' fluid icon='text width' iconPosition='left'  type="text" name="width"
                                            defaultValue={values.width} onChange={this.onChange} required />
                                        <Form.Input label='Length' fluid icon='xing' iconPosition='left'  type="text" name="length"
                                            defaultValue={values.length} onChange={this.onChange} required />
                                        <Form.Input label='Height' fluid icon='text height' iconPosition='left'  type="text" name="height"
                                            defaultValue={values.height} onChange={this.onChange} required />
                                    </Form>                                        
                                ) : (<Icon name='sync alternate' size='huge' color='yellow' loading></Icon>)}
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.update()}>
                                    <Icon name="edit"></Icon>Update
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(CapsuleTypeEdit);
import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import SidebarMenu from '../menu/SidebarMenu';
import AuthCrumb from '../menu/crumb/AuthCrumb';
import ResetPassword from './pages/ResetPassword';

class ResetPwdPanel extends Component{
    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <Grid textAlign='left' verticalAlign='middle'>
                                <Grid.Row>
                                    <Grid.Column>
                                        <AuthCrumb></AuthCrumb>
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row>
                                    <Grid.Column>
                                        <ResetPassword></ResetPassword>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default ResetPwdPanel;
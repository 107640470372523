/*
 * @Author: jiangbotao
 * @Date: 2019-12-01 13:47:14
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-14 14:31:32
 * @FilePath: \rem-rest-api\client\src\components\dataviz\hoteltable\page\HotelAdd.js
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './HotelAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class HotelAdd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
            remHubPublicIp: '',
            remHubLocalIp: ''

        }
    }

    /**
     * 成功通知
     */
    successNotify = () => toast.success("Location Add Successfully...", {
        onClose: () => this.props.history.push('/hotelpanel')
    });

    /**
     * 错误通知
     */
    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER
        });
    };

    /**
     * Form.Input内容变化的处理函数
     */
    onChange = (e, { name, value }) => this.setState({ [name]: value })

    /**
     * Add方法
     */
    add() {
        if (this.state.name.length === 0) {
            this.errNotify('Location Name is Null');
        } else {
            axios.post("/api/hotels/", {
                name: this.state.name.trimRight(),
                remHubLocalIp: this.state.remHubLocalIp,
                remHubPublicIp: this.state.remHubPublicIp

            }, {

                headers: {
                    'Authorization': 'Bearer ' + localStorage.usertoken
                }
            }).then(res => {
                this.successNotify();
            }).catch(err => {
                console.log(err);
            });
        }
    }

    render() {
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                                <h3><Icon name="warehouse"></Icon>&nbsp;&nbsp;Add Location</h3>
                            </Card.Content>
                            <Card.Content>

                                <Form style={{ textAlign: 'left' }}>
                                    <Form.Input label='Location Name' fluid icon='building' iconPosition='left' type="text" name="name"
                                        onChange={this.onChange} required />
                                    <Form.Input label='REM-hub Local IP' fluid icon='plug' iconPosition='left' type="text" name="remHubLocalIp"
                                        onChange={this.onChange} required />
                                    <Form.Input label='REM-hub Public IP' fluid icon='plug' iconPosition='left' type="text" name="remHubPublicIp"
                                        onChange={this.onChange} required />
                                </Form>

                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(HotelAdd);
/**
 * 权限路由Manager
 * 只有通过了登录的页面才能显示
 */
import React, { Component } from 'react'
import { Route, withRouter } from 'react-router-dom'
import jwt_decode from 'jwt-decode';

class EnsureLoggedInContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // 必须有usertoken且在有效期内
            isAuthenticated: localStorage.usertoken ?
                (Date.now() / 1000 < jwt_decode(localStorage.usertoken).exp ? true : false) :
                false
        }
    }

    componentDidMount() {
        // 如果没有登录，在0.01s内直接切换到登录页面
        if (!this.state.isAuthenticated) {
            const { history } = this.props;
            setTimeout(() => {
                history.replace("/login");
            }, 10)
        }
    }
    
    render(){
        let { component: Component, ...rest} = this.props;
        return  this.state.isAuthenticated ? 
            (<Route {...rest} render={(props) => (<Component {...props} />)} />) :
            (<div style={{ "width": "100%", "fontSize": "20px", "lineHeight": "50px" }}></div>)
    };
}
export default withRouter(EnsureLoggedInContainer);
/**
 * RoomAdd
 * Room具体项的添加
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './RoomAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class RoomAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            label: '',
            roomid: '',
            hotel: null,
            type: null,
            hotels: null,
            types: null
        }
    }

    componentWillMount(){
        axios.get("/api/hotels/",{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const hotels = [];
                res.data.hotels.map((hotel, index) => { 
                    return(hotels.push({key: index, text: hotel.name, value: hotel.id}))
                })
                this.setState({
                    hotels: hotels
                })
            }
        }).catch(err => {
            console.log(err);
        });

        axios.get("/api/capsuletypes/",{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken,
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        }).then(res => {
            if (res.data.success) {
                const types = [];
                res.data.capsuletypes.map((type, index) => { 
                    return(types.push({key: index, text: type.name, value: type.id}))
                })
                this.setState({
                    types: types
                })
            }
        }).catch(err => {
            console.log(err);
        });
    }

    successNotify = () => toast.success("Capsule Add Successfully...", {
        onClose: () => this.props.history.push('/roompanel')
    });

    /**
     * 错误通知
     */
    errNotify = (msg) => {
        toast.error(msg, {
            autoClose: 1000,
            draggable: false,
            closeButton: false,
            position: toast.POSITION.TOP_CENTER
        });
    };

    onChange = (e, { name, value }) => this.setState({ [name]: value })

    add() {
        if (this.state.label==='') {
            this.errNotify('Capsule Label is Null');
            return;
        }
        if (this.state.roomid==='') {
            this.errNotify('Product No. is Null');
            return;
        }
        if (this.state.hotel == null) {
            this.errNotify('Location is Null');
            return;
        }

        axios.post("/api/rooms/", {
            label: this.state.label,
            roomid: this.state.roomid,
            hotel: this.state.hotel,
            type: this.state.type
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
            }).then(res => {
                this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        const { hotels, types } = this.state;
        const values = { hotels, types };
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="bed"></Icon>&nbsp;&nbsp;Add Capsule</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left'}}>
                                    <Form.Input label='Capsule Label' fluid icon='hotel' iconPosition='left'  type="text" name="label"
                                        onChange={this.onChange} required />
                                    <Form.Input label='Product ID' fluid icon='hotel' iconPosition='left'  type="text" name="roomid"
                                        onChange={this.onChange} required />
                                    <Form.Dropdown label='Location' selection placeholder='Choose Location' name="hotel" 
                                        options={values.hotels} onChange={this.onChange} />
                                    <Form.Dropdown label='Capsule Type' selection placeholder='Choose Capsule Type' name="type" 
                                        options={values.types} onChange={this.onChange} />
                                </Form>
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(RoomAdd);
/**
 * HotelPanel
 * Hotel展示面板
 */
import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import HotelTable from './page/HotelTable';
import SidebarMenu from '../../menu/SidebarMenu';

class HotelPanel extends Component{
    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <HotelTable></HotelTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default HotelPanel;
/**
 * 首页页面
 */
import React, { Component } from 'react'
import { Segment, Grid, Header, Divider, Icon, Image } from 'semantic-ui-react'
import 'moment/locale/zh-cn';
import sleeep from '../sleeep.png'

class MainPanel extends Component{
    constructor() {
        super();
        this.state = {}
    }

    handleChange = (event, {name, value}) => {
        if (this.state.hasOwnProperty(name)) {
            this.setState({ [name]: value });
        }
    }
    
    render() {
        return (
            <Segment style={{ padding: '6em 0em 0em' ,borderBottom: 'none' }} vertical>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={16}>
                            <Header as='h3' style={{ fontSize: '3.5em' }}>
                            Our vision...
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={16} style={{ textAlign: 'center'}}>
                            <Header as='h0' style={{ fontSize: '8.5em'}}>
                                <Image src={sleeep}></Image>
                            </Header>
                            <Divider horizontal>
                                <Icon circular color='red' name='bed' size='huge'></Icon>
                            </Divider>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '3em' }}>
                                睡眠的科学
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '2em' }}>
                                Science of sleep                 
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '2em' }}>
                                Science du sommeil
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '3em' }}>
                                睡眠の科学
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '3em' }}>
                                수면 과학
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '3em' }}>
                                Наука о сне                 
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h3' style={{ fontSize: '3em' }}>
                                睡眠的科學
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '2em' }}>
                                Ciencia del sueño
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '3em' }}>
                                Uyku bilimi
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '2em' }}>
                                Επιστήμη του ύπνου             
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h3' style={{ fontSize: '3em' }}>
                                Ilmu tidur
                            </Header>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as='h2' style={{ fontSize: '2em' }}>
                            علم النوم   
                            </Header>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>          
        )
    }
}

export default MainPanel;
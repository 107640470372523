/*
 * @Author: jiangbotao
 * @Date: 2019-12-03 00:24:55
 * @LastEditors: jiangbotao
 * @LastEditTime: 2019-12-03 00:29:26
 * @FilePath: \rem-rest-api\client\src\components\dataviz\capsuletype\CapsuleTypePanel.js
 */
import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import CapsuleTypeTable from './page/CapsuleTypeTable';
import SidebarMenu from '../../menu/SidebarMenu';

class CapsuleTypePanel extends Component{
    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <CapsuleTypeTable></CapsuleTypeTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}

export default CapsuleTypePanel;
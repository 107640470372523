/**
 * IPAdd
 * IP具体项的编辑
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Grid, Card, Icon, Form, Button } from 'semantic-ui-react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';
import './IPAdd.css';

toast.configure({
    autoClose: 1000,
    draggable: false,
    closeButton: false,
    position: toast.POSITION.TOP_RIGHT
});

class IPAdd extends Component{
    constructor(props) {
        super(props);
        this.state = {
            ip: null,
            serial_number: null,
        }
    }

    successNotify = () => toast.success("IP Add Successfully...", {
        onClose: () => this.props.history.push('/ippanel')
    });

    // Input控件输入数据
    onChange = (e, { name, value }) => this.setState({ [name]:   value })

    add() {
        axios.post("/api/device/updateIp", {
            ip: this.state.ip,
            serial_number: this.state.serial_number,
        },{
            headers: {
                'Authorization': 'Bearer ' + localStorage.usertoken
            }
        }).then(res => {
            this.successNotify();
        }).catch(err => {
            console.log(err);
        });
    }
    
    render() {    
        return (
            <Grid textAlign='left' verticalAlign='middle'>
                <Grid.Row>
                    <Grid.Column mobile={16} tablet={8} computer={8}>
                        <Card fluid color='yellow'>
                            <Card.Content>
                            <h3><Icon name="podcast"></Icon>&nbsp;&nbsp;Add IP</h3>
                            </Card.Content>
                            <Card.Content>
                                <Form style={{ textAlign: 'left' }}>
                                    <Form.Input label='Serial Number' fluid icon='plug' iconPosition='left'  type="text" name="serial_number"
                                            onChange={this.onChange} required />
                                    <Form.Input label='IP' fluid icon='plug' iconPosition='left'  type="text" name="ip"
                                            onChange={this.onChange} required />
                                </Form>
                            </Card.Content>
                            <Card.Content>
                                <Button floated='right' icon labelPosition='left' primary size='small' onClick={() => this.add()}>
                                    <Icon name="add"></Icon>Add IP
                                </Button>
                            </Card.Content>
                        </Card>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }
}
export default withRouter(IPAdd);
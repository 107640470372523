/**
 * Device的主要数据展示组件
 */
import React, { Component } from 'react';
import { Segment, Grid } from 'semantic-ui-react';
import DeviceTable from './page/DeviceTable';
import SidebarMenu from '../../menu/SidebarMenu';

class DevicePanel extends Component{
    render() {
        return (
            <Segment vertical style={{ margin: "4em 0em -5em", padding: "2em 0em" }}>
                <Grid container stackable >
                    <Grid.Row>
                        <Grid.Column width={4}>
                            <SidebarMenu></SidebarMenu>
                        </Grid.Column>
                        <Grid.Column width={12}>
                            <DeviceTable></DeviceTable>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Segment>
        )
    }
}
export default DevicePanel;